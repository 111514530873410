















































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import ZbProfileService from '@/common/services/profile/profile.service';
import {
  getDummyEmail,
  getDummyPass,
  getUserId,
  setDummyEmail,
  setDummyPass,
} from '@/common/functions/auth';
import displayMessage from '@/common/functions/display-message';
import { UserBaseInfo } from '@/common/models/user';
import { UpdatePassword } from '@/common/models/profile';

@Component({})
export default class ChangeDummyCredenctials extends Vue {
  @Ref('userResetForm')
  userResetForm!: HTMLFormElement;

  profileService!: ZbProfileService;

  userId!: number;

  isEmailChanged = false;

  isPasswordChanged = false;

  enablePassReset = false;

  newPassDialog = '';

  newPassConfirmDialog = '';

  show1 = false;

  show2 = false;

  show3 = false;

  enableUserReset = false;

  oldUserDialog = '';

  showAlert = true;

  mounted() {
    this.loadData();
  }

  loadData() {
    this.profileService = new ZbProfileService();
    const hasDummyEmail = getDummyEmail();
    const hasDummyPass = getDummyPass();
    const savedUserId = getUserId();

    if (hasDummyEmail) {
      this.isEmailChanged = hasDummyEmail;
    }

    if (hasDummyPass) {
      this.isPasswordChanged = hasDummyPass;
    }

    if (savedUserId) {
      this.userId = +savedUserId;
    }
  }

  get passValue() {
    return this.isPasswordChanged;
  }

  get userValue() {
    return this.isEmailChanged;
  }

  get emailStatus() {
    if (this.isEmailChanged) {
      return 'E-Mail muss geändert werden';
    }
    return 'E-mail bereits angepasst';
  }

  get passStatus() {
    if (this.isPasswordChanged) {
      return 'Passwort muss geändert werden';
    }
    return 'Passwort bereits angepasst';
  }

  closeResetPassDialog() {
    this.userResetForm.reset();
    this.enablePassReset = false;
    this.enableUserReset = false;
    this.resetData();
  }

  async submitResetUserForm() {
    const userToUpdate: UserBaseInfo = {
      id: this.userId,
      email: this.oldUserDialog,
    };
    const response = await this.profileService.saveProfileEmail(userToUpdate);
    if (response.data) {
      this.isEmailChanged = false;
      this.enableUserReset = false;
      setDummyEmail(false);
      this.resetData();
      displayMessage(response.message, false, this.$store);
    } else {
      displayMessage(response.message, true, this.$store);
    }
  }

  async submitResetPasswordForm() {
    const updatePassword: UpdatePassword = {
      old_password: '',
      password: this.newPassDialog,
      password2: this.newPassConfirmDialog,
    };

    const confimrUpdate = await this.profileService.updateUserPassword(this.userId, updatePassword);
    if (confimrUpdate.statusCode !== 200) {
      displayMessage(confimrUpdate.message, true, this.$store);
    } else {
      setDummyPass(false);
      this.isPasswordChanged = false;
      this.enablePassReset = false;
      this.resetData();
      displayMessage(confimrUpdate.message, false, this.$store);
    }
  }

  resetData() {
    this.show1 = false;
    this.show2 = false;
    this.show3 = false;
    this.oldUserDialog = '';
    this.newPassDialog = '';
    this.newPassConfirmDialog = '';
  }

  @Watch('showAlert')
  onShowAlertChange() {
    this.loadData();
  }
}
